import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createStaticVNode as _createStaticVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = { id: "app" };
const _hoisted_2 = { class: "container mt-3" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_router_view = _resolveComponent("router-view");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _cache[1] || (_cache[1] = _createStaticVNode("<nav class=\"navbar navbar-expand navbar-dark bg-dark\"><a href=\"/signals\" class=\"navbar-brand\"> CopyTrader</a><div class=\"navbar-nav mr-auto\"><li class=\"nav-item\"><a href=\"/create-signal\" class=\"nav-link\">New Signal</a></li><li class=\"nav-item\"><a href=\"/signals\" class=\"nav-link\">Signals</a></li><li class=\"nav-item\"><a href=\"/create-alerts\" class=\"nav-link\">New Alert</a></li><li class=\"nav-item\"><a href=\"/alerts\" class=\"nav-link\">Alerts</a></li></div></nav>", 1)),
        _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_router_view),
            _cache[0] || (_cache[0] = _createElementVNode("p", null, "Main Page...", -1))
        ])
    ]));
}
