const routes = [
    {
        path: '/',
        alias: '/signals',
        name: 'signals',
        component: () => import('../components/SignalDetails.vue'),
    },
    {
        path: '/signals/:id',
        name: 'signal-details',
        component: () => import('../components/SignalDetails.vue'),
    },
    {
        path: '/positions',
        name: 'positions',
        component: () => import('../components/SignalDetails.vue'),
    },
    {
        path: '/create-signal',
        name: 'create-signal',
        component: () => import('../components/SignalDetails.vue'),
    },
    {
        path: '/accounts',
        name: 'accounts',
        component: () => import('../components/SignalDetails.vue'),
    },
    {
        path: '/alerts',
        name: 'alerts',
        component: () => import('../components/SignalDetails.vue'),
    },
    {
        path: '/create-alerts',
        name: 'create-alerts',
        component: () => import('../components/SignalDetails.vue'),
    },
];
export default routes;
